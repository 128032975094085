<link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700" rel="stylesheet" />
<!-- Navbar Transparent -->
<Navbar />
<!-- End Navbar -->
<section class="mb-8">
    <div class="page-header section-height-50 pt-3 pb-11 m-3 border-radius-lg">
        <!-- <span class="mask bg-gradient-dark opacity-6"></span> -->
        <!-- <div class="mask bg-gradient-dark opacity-6"></div> -->
        <div class="container">
            <div class="row">
                <img class="img-fluid" src="@/assets/images/header.svg" style="max-height: 250px;"/>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-5 text-center mx-auto">
                    <h1>Shorten Any Link</h1>
                    <p
                        class="text-md font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3 mt-2">
                        Build and protect your brand using powerful and recognizable short links. <a class="text-primary font-weight-normal">It's free forever!</a>
                      </p>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <slot />
    </div>
</section>

<footer class="footer py-5">
    <div class="container">
        <div class="row">
            <div class="col-8 mx-auto text-center mt-1">
                <p class="mb-0 text-secondary">Copyright © {{ (new Date().getFullYear()) }} Karsten Eckhardt</p>
            </div>
        </div>
    </div>
</footer>